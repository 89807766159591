import { ControlNumberResponse } from "@/api/controlNumber";
import { EntryRequest } from "@/api/entry";
import { createDefaultInsured, SCR040Form } from "@/forms/scr040";
import { CommitOptions, Module, MutationTree, Store } from "vuex";
import { RootState } from "..";
import constants from "common/src/constants";

const createDefaultState = () => ({
  msk01p: {
    tfMshscd: constants.SHOUHIN_CODE.SANKO_LIFE_GUARD,
    tfMshkpl: "",
    tfMsmsdtY: 0,
    tfMsmsdtM: 0,
    tfMsmsdtD: 0,
    tfMskykjSei: "",
    tfMskykjMei: "",
    tfMskyknSei: "",
    tfMskyknMei: "",
    tfMskybtY: undefined,
    tfMskybtM: undefined,
    tfMskybtD: undefined,
    tfMsskdtY: undefined,
    tfMsskdtM: undefined,
    tfMsskdtD: undefined,
    tfMslcsm: constants.HAIIIE.HAI,
    tfMskyyn: "",
    tfMskya1: "",
    tfMskya2Tatemono: "",
    tfMskya2HeyaBango: "",
    tfMskya3: "",
    tfMskya4: "",
    tfMskytl: "",
    tfMskyt2: "",
    tfMsthum: constants.UMU.NASI,
    tfMsthcm: "",
    tfMsthsr: "",
    tfMsconv: "",
    tfMshkhh: "",
    tfMsdrcd: "",
    tfMsstcd: "",
    tfMsbscd: "",
  },
  msk02p: [],
  location: {
    tfMhhkyn: "",
    tfMhhka1: "",
    tfMhhka2Tatemono: "",
    tfMhhka2HeyaBango: "",
    tfMhhka3: "",
    tfMhhka4: "",
  },
  security: {
    tfHnuuid: "",
  },
  control: {
    tfCnkyno: "",
  },
});

/**
 * Vuexによる管理を行う対象
 */
export const state: EntryRequest = createDefaultState();

/**
 * stateの状態を変更するためのMutation名定義
 * 各Storeをnamespacedにしないため、ユニークなMutation名となるようにする
 */
export enum EntryMutationTypes {
  /**
   * 申込日セット
   */
  SET_ENTRY_DATE = "SET_ENTRY_DATE",
  /**
   * シリアル番号セット
   */
  SET_SERIAL = "SET_SERIAL",
  /**
   * 登録情報セット
   */
  SET_ENTRY = "SET_ENTRY",
  /**
   * 管理番号より取得したデータセット
   */
  SET_CONTROL_NUMBER_DATA = "SET_CONTROL_NUMBER_DATA",
  /**
   * コンビニコードをセット
   */
  SET_CONVENI_CODE = "SET_CONVENI_CODE",
  /**
   * 初期化処理
   */
  RESET = "RESET_ENTRY",
}

/**
 * Mutation定義
 */
export type Mutations<S = EntryRequest> = {
  [EntryMutationTypes.SET_ENTRY_DATE](state: S, entryDate: string): void;
  [EntryMutationTypes.SET_SERIAL](state: S, serial: string): void;
  [EntryMutationTypes.SET_ENTRY](state: S, newState: SCR040Form): void;
  [EntryMutationTypes.SET_CONTROL_NUMBER_DATA](state: S, data: ControlNumberResponse): void;
  [EntryMutationTypes.SET_CONVENI_CODE](state: S, code: string): void;
  [EntryMutationTypes.RESET](state: S): void;
};

/**
 * Mutation実装
 */
export const mutations: MutationTree<EntryRequest> & Mutations = {
  [EntryMutationTypes.SET_ENTRY_DATE](state: EntryRequest, entryDate: string) {
    const date = new Date(entryDate);
    state.msk01p.tfMsmsdtY = date.getFullYear();
    state.msk01p.tfMsmsdtM = date.getMonth() + 1;
    state.msk01p.tfMsmsdtD = date.getDate();
  },
  [EntryMutationTypes.SET_SERIAL](state: EntryRequest, serial: string) {
    state.security.tfHnuuid = serial;
  },
  [EntryMutationTypes.SET_ENTRY](state: EntryRequest, entry: SCR040Form) {
    state.msk01p = entry.msk01p;
    const enabledInsureds = entry.msk02p.filter((e) => e.enabled);
    enabledInsureds.forEach((e, i) => (e.tfMhhkno = (i + 1).toString().padStart(3, "0")));
    state.msk02p = enabledInsureds;
    state.location = entry.location;
  },
  [EntryMutationTypes.SET_CONTROL_NUMBER_DATA](state: EntryRequest, data: ControlNumberResponse) {
    state.control.tfCnkyno = data.tf_cnkyno;

    const mskjSpaceIndex = data.tf_cnmskj.indexOf("　");
    state.msk01p.tfMskykjSei = mskjSpaceIndex > 0 ? data.tf_cnmskj.substring(0, mskjSpaceIndex) : data.tf_cnmskj;
    state.msk01p.tfMskykjMei = mskjSpaceIndex > 0 ? data.tf_cnmskj.substring(mskjSpaceIndex + 1) : "";

    const msknSpaceIndex = data.tf_cnmskn.indexOf("　");
    state.msk01p.tfMskyknSei = msknSpaceIndex > 0 ? data.tf_cnmskn.substring(0, msknSpaceIndex) : data.tf_cnmskn;
    state.msk01p.tfMskyknMei = msknSpaceIndex > 0 ? data.tf_cnmskn.substring(msknSpaceIndex + 1) : "";

    const mstl = data.tf_cnmstl.split("/");
    state.msk01p.tfMskybtY = Number.parseInt(mstl[0]);
    state.msk01p.tfMskybtM = mstl.length > 1 ? Number.parseInt(mstl[1]) : undefined;
    state.msk01p.tfMskybtD = mstl.length > 2 ? Number.parseInt(mstl[2]) : undefined;

    state.location.tfMhhkyn = data.tf_cnttyn.replace("-", "");
    state.location.tfMhhka1 = data.tf_cnttad;
    state.location.tfMhhka3 = data.tf_cnttak;
    state.location.tfMhhka4 = data.tf_cnttnk;
    state.location.tfMhhka2Tatemono = data.tf_cnttnm;
    state.location.tfMhhka2HeyaBango = data.tf_cnhyno;

    if (state.msk02p.length > 0) {
      state.msk02p[0].tfMhkozo = data.tf_cnttkz;
    } else {
      const insured = createDefaultInsured();
      insured.tfMhkozo = data.tf_cnttkz;
      state.msk02p[0] = insured;
    }

    const hksk = data.tf_cnhksk.split("/");
    state.msk01p.tfMsskdtY = Number.parseInt(hksk[0]);
    state.msk01p.tfMsskdtM = hksk.length > 1 ? Number.parseInt(hksk[1]) : undefined;
    state.msk01p.tfMsskdtD = hksk.length > 2 ? Number.parseInt(hksk[2]) : undefined;

    if (data.tf_cnhony.length > 1) {
      state.msk01p.tfMshscd = data.tf_cnhony.charAt(0);
      state.msk01p.tfMshkpl = data.tf_cnhony.charAt(1);
    }

    state.msk01p.tfMshkhh = data.tf_cnhshr;
    state.msk01p.tfMsdrcd = data.tf_cndrcd;
    state.msk01p.tfMsstcd = data.tf_cnstcd;
    state.msk01p.tfMsbscd = data.tf_cnbscd;
  },
  [EntryMutationTypes.SET_CONVENI_CODE](state: EntryRequest, code: string) {
    state.msk01p.tfMsconv = code;
  },
  [EntryMutationTypes.RESET](state: EntryRequest) {
    const def = createDefaultState();
    state.msk01p = def.msk01p;
    state.msk02p = def.msk02p;
    state.location = def.location;
    state.security = def.security;
    state.control = def.control;
  },
};

/**
 * ストアの型定義
 */
export type EntryStore<S = EntryRequest> = Omit<Store<S>, "commit"> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<Mutations[K]>;
};

/**
 * ストア
 */
export const entryStore: Module<EntryRequest, RootState> = {
  state,
  mutations,
};
