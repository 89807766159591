import { AxiosResponse } from "axios";
import { anonymousRequest } from "common/src/utils/request";

/**
 * API0230 申込情報登録 申込データ
 */
export type Entry = {
  /** 保険商品コード */
  tfMshscd: string;
  /** 保険プラン */
  tfMshkpl: string;
  /** 申込日(年) */
  tfMsmsdtY: number;
  /** 申込日(月) */
  tfMsmsdtM: number;
  /** 申込日(日) */
  tfMsmsdtD: number;
  /** 契約者名漢字(姓) */
  tfMskykjSei: string;
  /** 契約者名漢字(名) */
  tfMskykjMei: string;
  /** 契約者名カナ(姓) */
  tfMskyknSei: string;
  /** 契約者名カナ(名) */
  tfMskyknMei: string;
  /** 契約者生年月日(年) */
  tfMskybtY: number | undefined;
  /** 契約者生年月日(月) */
  tfMskybtM: number | undefined;
  /** 契約者生年月日(日) */
  tfMskybtD: number | undefined;
  /** 始期日(年) */
  tfMsskdtY: number | undefined;
  /** 始期日(月) */
  tfMsskdtM: number | undefined;
  /** 始期日(日) */
  tfMsskdtD: number | undefined;
  /** 目的の所在地と同じ */
  tfMslcsm: string;
  /** 契約者郵便番号 */
  tfMskyyn: string;
  /** 契約者住所1 */
  tfMskya1: string;
  /** 契約者住所2(建物名) */
  tfMskya2Tatemono: string;
  /** 契約者住所2(部屋番号) */
  tfMskya2HeyaBango: string;
  /** 契約者住所3 */
  tfMskya3: string;
  /** 契約者住所4 */
  tfMskya4: string;
  /** 契約者電話番号 */
  tfMskytl: string;
  /** 契約者電話番号（携帯） */
  tfMskyt2: string;
  /** 他保険有無 */
  tfMsthum: string;
  /** 他保険会社名 */
  tfMsthcm: string;
  /** 他保険種類 */
  tfMsthsr: string;
  /** コンビニ_支払先コンビニコード */
  tfMsconv: string;
  /** 払込方法 */
  tfMshkhh: string;
  /** 代理店コード */
  tfMsdrcd: string;
  /** 支店コード */
  tfMsstcd: string;
  /** 募集人コード */
  tfMsbscd: string;
};

/**
 * API0230 申込被保険者情報
 */
export type Insured = {
  /** 被保険者が設定されているかどうか(APIリクエストには不要な値) */
  enabled: boolean;
  /** 被保険者番号 */
  tfMhhkno: string;
  /** 所有区分 */
  tfMhsykb: string;
  /** 用途 */
  tfMhytkb: string;
  /** 構造 */
  tfMhkozo: string;
  /** 契約者と同じ */
  tfMhkysm: string;
  /** 被保険者カナ(姓) */
  tfMhhkskSei: string;
  /** 被保険者カナ(名) */
  tfMhhkskMei: string;
  /** 被保険者漢字(姓) */
  tfMhhksjSei: string;
  /** 被保険者漢字(名) */
  tfMhhksjMei: string;
  /** 被保険者性別 */
  tfMhhksx: string;
  /** 被保険者生年月日(年) */
  tfMhhkbtY: number | undefined;
  /** 被保険者生年月日(月) */
  tfMhhkbtM: number | undefined;
  /** 被保険者生年月日(日) */
  tfMhhkbtD: number | undefined;
  /** 被保険者電話番号 */
  tfMhhktl: string;
  /** 被保険者電話番号（携帯電話） */
  tfMhhkt2: string;
  /** 被保険者メールアドレス */
  tfMhhkma: string;
};

/**
 * API0230 目的の所在地
 */
export type Location = {
  /** 郵便番号 */
  tfMhhkyn: string;
  /** 住所漢字 */
  tfMhhka1: string;
  /** 建物名 */
  tfMhhka2Tatemono: string;
  /** 部屋番号 */
  tfMhhka2HeyaBango: string;
  /** 住所カナ */
  tfMhhka3: string;
  /** 建物名カナ */
  tfMhhka4: string;
};

/**
 *API0230 セキュリティ
 */
export type Security = {
  /** 本人確認UUID */
  tfHnuuid: string;
};

/**
 * API0230 管理番号
 */
export type Control = {
  /** 管理番号 */
  tfCnkyno: string;
};

/**
 * API0230 リクエストモデル
 */
export type EntryRequest = {
  msk01p: Entry;
  msk02p: Insured[];
  location: Location;
  security: Security;
  control: Control;
};

/**
 * API0230 レスポンスモデル
 */
export type EntryResponse = {
  /** コンビニ_オーダーID */
  tfMsodid: string;
  /** コンビニ_支払先コンビニコード */
  tfMsconv: string;
  /** コンビニ_確認番号 */
  tfMscono: string;
  /** コンビニ_受付番号 */
  tfMsreno: string;
  /** コンビニ_支払期限日時 */
  tfMspydt: string;
  /** コンビニ_払込表等URL */
  receiptUrl: string;
};

/**
 * API0230 申込情報登録
 * @param entry
 */
export const postEntry = async (entry: EntryRequest): Promise<AxiosResponse<EntryResponse>> => {
  return await anonymousRequest.post("/v1/entries", entry);
};
