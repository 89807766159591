/**
 * 共通定数定義モジュール
 */
export default Object.freeze({
  /**
   * 認証タイプ
   */
  AUTH_TYPE: {
    MYPAGE: "0",
    ADMIN: "1",
  },
  ARI: {
    ARI: "1",
  },
  SHOUHIN_CODE: {
    SANKO_LIFE_1: "0",
    SANKO_LIFE_2: "1",
    SANKO_LIFE_3: "2",
    SANKO_LIFE_GUARD: "3",
  },
  UMU: {
    ARI: "1",
    NASI: "2",
  },
  GENDER: {
    MAN: "1",
    WOMAN: "2",
    COMPANY: "3",
    UNKNOWN: "4",
  },
  HAIIIE: {
    HAI: "1",
    IIE: "2",
  },
  HOKEN_SYURUI: {
    HOUSE: "家財保険",
    LIABILITY: "個人賠償責任保険",
  },
  NYUKIN_STATUS: {
    SYONIN_NYUKINZUMI: "2",
    SYONIN_MINYUKIN: "3",
    HORYU_NYUKINZUMI: "4",
    HORYU_MINYUKIN: "5",
    KAIYAKU_TORIKESHI_NADO: "9",
  },
  STATUS: {
    KEIYAKU: "1",
    KOUSHIN: "2",
  },
  SYOZOKU: {
    SYOTAN: "01",
    AGENT: "02",
  },
  SYOYU_KBN: {
    JYUTAKU: "1",
  },
  YOUTO: {
    KAZAI: "1",
  },
  KOZO: {
    MOKUZO: "1",
    HIMOKUZO: "2",
  },
  HARAIKOMI_HOUHOU: {
    TYOKU: "1",
    CONVENI: "2",
  },
  CONVENIENCE_STORE: {
    SEVEN_ELEVEN: "00007",
    LAWSON: "10001",
    FAMILY_MART: "10002",
  },
  KEIZOKU_ISHI: {
    ARI: "1",
    NASI: "2",
  },
  SYOUKEN_HAKKO: {
    ENABLE: "1",
  },
  MOUSHIKOMI_KEIRO: {
    KAMI: "1",
    WEB: "2",
  },

  /** アクセストークンキー マイページ */
  ACCESS_TOKEN_KEY_MYPAGE: "acsTokenMypage",

  /** リフレッシュトークンキー マイページ */
  REFRESH_TOKEN_KEY_MYPAGE: "refTokenMypage",

  /** アクセストークンキー 管理ページ */
  ACCESS_TOKEN_KEY_ADMIN: "acsTokenAdmin",

  /** リフレッシュトークンキー 管理ページ */
  REFRESH_TOKEN_KEY_ADMIN: "refTokenAdmin",

  /** 代表被保険者 被保険者番号 */
  MAIN_INSURED_NUMBER: "001",

  /** 全角カナの範囲を表すUnicode文字列 */
  ZENKAKU_KANA_CODES: "\u30A0-\u30FF",

  /** 全角数字の範囲を表すUnicode文字列 */
  ZENKAKU_NUMERIC_CODES: "\uFF10-\uFF19",

  /** 全角スペースを表すUnicode文字列 */
  ZENKAKU_SPACE_CODES: "\u3000",

  /** 全角英字を表す正規表現の文字列 */
  ZENKAKU_ALPHA_CODES: "ａ-ｚＡ-Ｚ",

  /**
   * 全角記号を表す正規表現の文字列
   *
   * ！＂＃＄％＆＇（）＊＋，－．／
   * ：；＜＝＞？＠
   * ［＼］＾＿｀
   * ｛｜｝～
   * 、。〃〄々〆〇〈〉《》「」『』【】〒〓〔〕〖〗〘〙〚〛〜
   * ”’・
   *
   * See https://www.pre-practice.net/2018/01/g_78.html
   */
  ZENKAKU_SYMBOL_CODES: "！-／：-＠［-｀｛-～、-〜”’・",

  /** 全角ローマ数字を表す正規表現の文字列 */
  ZENKAKU_ROMAN_NUMERALS: "ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅪⅫ",
});
